import { Box, Button, Container, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Routes } from 'constants/routes.constants';

import { ImgScribbleDoubleLine } from 'components/@icons';

const NotFound = () => {
  const router = useRouter();
  const theme = useTheme();

  const handleClick = () => router.push(Routes.Root);

  return (
    <Container sx={{ pt: { xs: 14, md: 16 } }}>
      <Box maxWidth={{ md: 466 }}>
        <Box display="inline-block" mb={10}>
          <Typography marginBottom={-4} variant="h1">
            404
          </Typography>
          <ImgScribbleDoubleLine width="auto" fill={theme.palette.pink[200]} />
        </Box>
        <Typography variant="h4" mb={2}>
          <FormattedMessage id="not_found.page.title" />
        </Typography>
        <Typography mb={10}>
          <FormattedMessage id="not_found.page.description" />
        </Typography>
        <Button variant="contained" onClick={handleClick}>
          <FormattedMessage id="not_found.button.back" />
        </Button>
      </Box>
    </Container>
  );
};

export default NotFound;
